





























import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component
export default class Search extends Vue {
  baseUrl = 'http://openlibrary.org';
  books = [];
  query = '';

  async search() {
    const response = await axios.get(this.baseUrl + `/search.json?title=${this.query}`);
    this.books = await response.data.docs;
  }

  viewDetails(book: any) {
    this.$router.push({ path: 'details', query: {
      title: book.title,
      authors: book.author_name && book.author_name.join(', '),
      year: book.first_publish_year,
      cover_id: book.cover_edition_key
    }});
  }
}
